import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";

interface OverlapEffectProps {
  isOpenOverlap: boolean;
  showOverlapEffect: boolean;
  setIsOpenOverlap: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OverlapEffect = React.memo(
  ({ isOpenOverlap, setIsOpenOverlap, showOverlapEffect }: OverlapEffectProps) => {
    if (!showOverlapEffect) return null;
    return (
      <>
        <div
          data-testid="gradient-fade"
          className={`gradient-fade ${isOpenOverlap ? "off" : ""}`}
        ></div>
        <button
          type="button"
          className="more-btn"
          onClick={() => setIsOpenOverlap((prevstate) => !prevstate)}
        >
          {isOpenOverlap ? "Show less" : "Show more"}
        </button>
      </>
    );
  }
);

interface OverlapData {
  maxHeight: number;
  fullHeight: number;
  overlapState: string;
}

export function useContentOverlap(
  leftContentRef: React.RefObject<HTMLDivElement>,
  rightContentRef: React.RefObject<HTMLDivElement>
) {
  const [isOpenOverlap, setIsOpenOverlap] = useState(false);
  const [overlapData, setOverlapData] = useState<OverlapData>({
    maxHeight: 0,
    fullHeight: 0,
    overlapState: "",
  });

  const getBiggerContent = (leftHeight: number, rightHeight: number) => {
    if (leftHeight === rightHeight) return "";
    return leftHeight < rightHeight ? "changes" : "saved";
  };

  const calculateOverlap = useCallback(() => {
    if (leftContentRef.current && rightContentRef.current) {
      const leftHeight = leftContentRef.current.scrollHeight;
      const rightHeight = rightContentRef.current.scrollHeight;
      const minHeight = Math.min(leftHeight, rightHeight);
      const maxHeight = Math.max(leftHeight, rightHeight);

      setOverlapData({
        maxHeight: minHeight,
        fullHeight: maxHeight,
        overlapState: getBiggerContent(leftHeight, rightHeight),
      });
    }
  }, [leftContentRef, rightContentRef]);

  useEffect(() => {
    const debouncedCalculateOverlap = debounce(calculateOverlap, 250);
    calculateOverlap();
    window.addEventListener("resize", debouncedCalculateOverlap);

    return () => {
      window.removeEventListener("resize", debouncedCalculateOverlap);
      debouncedCalculateOverlap.cancel();
    };
  }, [calculateOverlap]);

  return {
    maxHeight: overlapData.maxHeight,
    fullHeight: overlapData.fullHeight,
    overlapState: overlapData.overlapState,
    isOpenOverlap,
    setIsOpenOverlap,
  };
}

import axios from "axios";
import getConfig from "./config";

const api = axios.create({
  baseURL: getConfig()?.APP_API,
  withCredentials: getConfig()?.REQUEST_WITH_CREDENTIALS || false,
  headers: {
    tenantId: localStorage.getItem("tenantId") ?? "",
  },
});

export default api;

import { useState } from "react";
import { MappingContext } from "../../context/mappingDataContext";
import SchemaEditedInfoDialog from "../SchemaEditedInfoDialog/SchemaEditedInfoDialog";
import ModalEntrance from "../../ui/ModalEntrance/ModalEntrance";

type Props = {
  headerTitle: string;
};

const PageHeader = (props: Props) => {
  const [showDialogSchemaInfo, setShowDialogSchemaInfo] = useState(false);
  const {
    dataComponent: { schemaName },
    isEditMode,
  } = MappingContext();

  return (
    <>
      <h1 className="avalara_title">{props.headerTitle}</h1>
      {isEditMode && (
        <div className="editing-mode">
          Editing schema:{" "}
          <span className="value" onClick={() => setShowDialogSchemaInfo(true)}>
            <span>{schemaName}</span>
          </span>
        </div>
      )}
      <hr style={{ marginBottom: "26px" }} />

      <ModalEntrance id="SchemaEditedInfo" showElement={showDialogSchemaInfo}>
        <SchemaEditedInfoDialog
          showDialog={showDialogSchemaInfo}
          setShowDialog={setShowDialogSchemaInfo}
        />
      </ModalEntrance>
    </>
  );
};

export default PageHeader;

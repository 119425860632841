import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

interface IFadeInEffectProps {
  children: React.ReactElement;
  showElement: boolean;
  id: string;
  duration?: number;
}

const FadeInEffect: React.FunctionComponent<IFadeInEffectProps> = (props) => {
  const duration = props.duration ?? 0.6;
  return (
    <AnimatePresence>
      {props.showElement && (
        <motion.div
          key={props.id}
          transition={{ duration }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default FadeInEffect;

import * as xlsx from "xlsx";
import { hasDuplicates } from "./string";
import { IHeaders } from "../domain/mappingTypes";

type TReadXlsFile = {
  workbookSheetNames: string[];
  headerListNames: string[];
  headerListObjects: Partial<IHeaders>[];
  message: string;
};

export const readXlsFile = (file: File, headerRowInit: number): Promise<TReadXlsFile> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const headerListObjects: Partial<IHeaders>[] = [];

    reader.onload = (e) => {
      try {
        const data = e.target!.result;
        const workbook = xlsx.read(data, {
          type: "array",
          cellNF: true,
          cellDates: true,
        });

        const firstSheetName = workbook.SheetNames[0];
        const workbookSheetNames = workbook.SheetNames;
        const firstSheet = workbook.Sheets[firstSheetName];
        const range = getRangeFromXLSX(firstSheet);

        const sheetData: string[][] = xlsx.utils.sheet_to_json(firstSheet, {
          header: 1,
          raw: false,
          range: range,
          defval: "",
        });
        const sheetDataTyped: any[][] = xlsx.utils.sheet_to_json(firstSheet, {
          header: 1,
          range: range,
          defval: "",
        });

        const headerListNames = getHeadersFromXLSX(sheetData, headerRowInit);
        const sampleData: string[][] = sheetData.slice(headerRowInit + 1, 6);

        const hasOutOfMemory = !firstSheet;
        const hasNoFoundHeaders = headerListNames.length === 0;
        const hasDuplicatedHeaders = hasDuplicates(headerListNames);
        const message = generateXlsxErrorMessage(
          hasOutOfMemory,
          hasNoFoundHeaders,
          hasDuplicatedHeaders
        );

        headerListNames.forEach((header, index) => {
          const cell = sheetData[headerRowInit + 1]?.[index] || "";
          const cellTyped = sheetDataTyped[headerRowInit + 1]?.[index] || "";

          headerListObjects.push({
            name: header,
            type: getTypeFromCell(cell, cellTyped),
            data: getSampleData(sampleData, index),
          });
        });
        resolve({ workbookSheetNames, headerListNames, headerListObjects, message });
      } catch (error: any) {
        reject(new Error(error.message || "Error processing the XLSX file."));
      }
    };

    reader.onerror = (_) => {
      reject(new Error("An error occurred while accessing the file."));
    };
    reader.readAsArrayBuffer(file);
  });
};

export const getHeadersFromXLSX = (sheetData: string[][], headerRowInit: number): string[] => {
  if (sheetData.length && sheetData[headerRowInit]) {
    const isEmptyLine = sheetData[headerRowInit].every((cellValue) => cellValue === "");
    return isEmptyLine
      ? []
      : sheetData[headerRowInit].filter((cellValue) => cellValue.trim() !== "");
  }
  return [];
};

export const getRangeFromXLSX = (spreadSheet: any) => {
  if (!spreadSheet["!ref"]) return 0;
  const lastColumn = xlsx.utils.decode_range(spreadSheet["!ref"]).e.c;
  const totalRows = xlsx.utils.decode_range(spreadSheet["!ref"]).e.r + 1;
  return totalRows > 100 ? `A1:${xlsx.utils.encode_col(lastColumn)}100` : 0;
};

export function getSampleData(sampleData: any[][], cellIndex: number): string[] {
  const arrayReturn: string[] = [];
  sampleData.forEach((row) => {
    arrayReturn.push(row[cellIndex]);
  });
  return arrayReturn;
}

export function getTypeFromCell(cell: string, cellTyped: any) {
  if (cellTyped instanceof Date) {
    return "DateTime";
  } else if (isIntegerString(cell)) {
    return "Integer";
  } else if (isFloatString(cell)) {
    return "Decimal";
  } else {
    return typeof cellTyped === "boolean" ? "Boolean" : "String";
  }
}

export function isXls(file: File) {
  if (
    file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    file.type === "application/vnd.ms-excel"
  ) {
    return true;
  } else {
    return false;
  }
}

export const generateXlsxErrorMessage = (
  hasOutOfMemory: boolean,
  hasNoFoundHeaders: boolean,
  hasDuplicatedHeaders: boolean
) => {
  switch (true) {
    case hasOutOfMemory:
      return "The file could not be read possibly due to its size. Try shrinking this file and uploading it again.";
    case hasNoFoundHeaders:
      return "No headers found. Please check the sheet and header row number.";
    case hasDuplicatedHeaders:
      return "Duplicate columns in header.";
    default:
      return "An unexpected error occurred.";
  }
};

function isIntegerString(str: string) {
  return /^\d+$/.test(str);
}

function isFloatString(str: string) {
  return /^-?\d+(\.\d+)?$/.test(str);
}

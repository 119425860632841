import React from "react";

import { useNavigate } from "react-router-dom";
import { Alert, Dialog } from "@avalara/skylab-react";
import FadeInEffect from "../../ui/FadeInEffect/FadeInEffect";
import { MappingContext } from "../../context/mappingDataContext";
import ScaleEffect from "../../ui/ScaleEffect/ScaleEffect";
import SlideDownEffect from "../../ui/SlideDownEffect/SlideDownEffect";
import { IHeaders } from "../../domain/mappingTypes";
import ValidateHeaderChanges from "../ValidateHeaderChanges/ValidadeHeaderChanges";
import { calculateColumnChanges } from "../../utils/schema";

interface ICsvDialogProps {
  file: File;
  showDialog: boolean;
  showConfirmHeadersCsv: boolean;
  csvHasError: boolean;
  uploadErrorMessage: string;
  readHeaders: Partial<IHeaders>[];
  readCsv: (file: File) => void;
  setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setIsProcessingUpload: React.Dispatch<React.SetStateAction<boolean>>;
  setShowConfirmHeadersCsv: React.Dispatch<React.SetStateAction<boolean>>;
}

const CsvDialog: React.FunctionComponent<ICsvDialogProps> = (props) => {
  const { setHeaders, dataComponent, setData, isEditMode } = MappingContext();
  const navigate = useNavigate();

  const { removedColumns } = calculateColumnChanges(props.readHeaders);
  const hasColumnMissing = removedColumns.length > 0;

  const handleConfirm = () => {
    setHeaders(props.readHeaders as IHeaders[]);
    setTimeout(() => navigate("/mapping"), 100);
    props.setShowDialog(false);
  };

  return (
    <Dialog
      id="dialogId"
      onSDismiss={(e) => {
        if (e.detail.id === "dialogId") {
          props.setShowConfirmHeadersCsv(false);
          props.setIsProcessingUpload(false);
          props.setShowDialog(false);
        }
      }}
      style={{ minWidth: "560px" }}
      open={props.showDialog}
      className="overflow-visible"
      noScroll
    >
      <div slot="header" id="dialog-title" data-testid="dialog-separator-id">
        {!props.showConfirmHeadersCsv && (
          <FadeInEffect id="dialogId-header-1" showElement={!props.showConfirmHeadersCsv}>
            <div>Please set the delimiter for your import file</div>
          </FadeInEffect>
        )}

        {props.showConfirmHeadersCsv && (
          <FadeInEffect id="dialogId-header-2" showElement={props.showConfirmHeadersCsv}>
            <div slot="header" id="dialog-title" data-testid="dialog-separator-id">
              Please confirm the found headers
            </div>
          </FadeInEffect>
        )}
      </div>
      <div slot="body">
        <SlideDownEffect id="dialogId-body-1" showElement={!props.showConfirmHeadersCsv}>
          <fieldset style={{ padding: "2px" }}>
            <label className="required">Delimiter:</label>
            <select
              aria-label="select delimiter"
              id="separatorId"
              data-testid="separator-id"
              className="template-select"
              required
              value={dataComponent.schemaSeparator || ""}
              onChange={(value) =>
                setData((d) => {
                  return { ...d, schemaSeparator: value.target.value };
                })
              }
            >
              <option value="">Select...</option>
              <option value=";">Semicolon ';'</option>
              <option value=",">Comma ','</option>
              <option value="\t">Tab '\t'</option>
            </select>
          </fieldset>
        </SlideDownEffect>

        <ScaleEffect
          id="dialogId-body-2"
          showElement={props.showConfirmHeadersCsv}
          initialWidth={460}
        >
          <>
            <div className="headers-preview">
              <div>
                You have selected header row{" "}
                <span>
                  "<b> {dataComponent.headerRowPosition} </b>"
                </span>
                <span> and delimiter </span>
                <span>
                  "<b> {dataComponent.schemaSeparator} </b>"
                </span>
              </div>
              <div className="no-wrap-big-screen">
                Based on this selection the following column headings will be used during mapping:
              </div>
            </div>

            {props.csvHasError ? (
              <Alert status="error" noDismiss>
                <div data-testid="user-message-upload-error">{props.uploadErrorMessage}</div>
              </Alert>
            ) : (
              <div className="headers-preview-itens-container">
                {isEditMode && <ValidateHeaderChanges readHeaders={props.readHeaders} />}
                {!hasColumnMissing &&
                  props.readHeaders.map((item, index) => (
                    <div className="itens" key={index}>
                      {item.name}
                    </div>
                  ))}
              </div>
            )}
          </>
        </ScaleEffect>
      </div>

      <div slot="footer">
        {!props.showConfirmHeadersCsv && (
          <FadeInEffect id="dialogId-footer-1" showElement={!props.showConfirmHeadersCsv}>
            <>
              <button
                className="secondary small"
                data-testid="button-cancel-id"
                onClick={() => {
                  props.setShowDialog(false);
                  props.setIsProcessingUpload(false);
                }}
              >
                Cancel
              </button>
              <button
                className="primary small"
                data-testid="button-save-id"
                disabled={dataComponent.schemaSeparator === ""}
                onClick={() => {
                  props.setIsProcessingUpload(false);
                  props.readCsv(props.file);
                }}
              >
                Continue
              </button>
            </>
          </FadeInEffect>
        )}

        {props.showConfirmHeadersCsv && (
          <FadeInEffect id="dialogId-footer-2" showElement={props.showConfirmHeadersCsv}>
            <>
              <button
                className="secondary small"
                data-testid="button-cancel-id"
                onClick={() => {
                  props.setShowConfirmHeadersCsv(false);
                }}
              >
                Back
              </button>
              <button
                className="primary small"
                data-testid="button-confirm-id"
                disabled={
                  dataComponent.schemaSeparator === "" || props.csvHasError || hasColumnMissing
                }
                onClick={handleConfirm}
              >
                Confirm
              </button>
            </>
          </FadeInEffect>
        )}
      </div>
    </Dialog>
  );
};

export default CsvDialog;

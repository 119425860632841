import { IHeaders } from "../domain/mappingTypes";

export const calculateColumnChanges = (readHeaders: Partial<IHeaders>[]) => {
  const snapshot = sessionStorage.getItem("snapshot");
  const savedHeaders: Partial<IHeaders>[] = snapshot ? JSON.parse(snapshot).headers : [];

  const savedHeaderListName = savedHeaders.map((obj) => obj.name);
  const editedHeadersListName = readHeaders.map((obj) => obj.name);

  const removedColumns = savedHeaderListName.filter(
    (item) => !editedHeadersListName.includes(item)
  );

  const addedColumns = editedHeadersListName.filter((item) => !savedHeaderListName.includes(item));

  return { removedColumns, addedColumns };
};

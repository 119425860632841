import { IHeaders } from "../domain/mappingTypes";

export const readCsv = async (
  _file: File,
  headerPosition: number,
  schemaSeparator: string
): Promise<{
  foundHeaders: string[];
  headerList: IHeaders[];
  dataCSV: string[][];
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      try {
        const csvData = reader.result as string;
        const csvRecordsArray = csvData.split(/\r\n|\n/);
        const headerRowPosition = headerPosition - 1;
        const parsedSchemaSeparator = schemaSeparator === "\\t" ? "\t" : schemaSeparator;

        const foundHeaders = parseCsvRow(csvRecordsArray[headerRowPosition], parsedSchemaSeparator);
        const dataCSV = parseDataRows(csvRecordsArray, headerRowPosition, parsedSchemaSeparator);
        const headerList = createHeaderList(foundHeaders, dataCSV);

        resolve({ foundHeaders, headerList, dataCSV });
      } catch (_) {
        reject(new Error("Error reading file"));
      }
    };
    reader.onerror = () => reject(reject(new Error("An error occurred while accessing the file.")));
    reader.readAsText(_file);
  });
};

export const parseCsvRow = (row: string, separator: string): string[] =>
  row ? row.split(separator) : [];

export const parseDataRows = (
  csvRecordsArray: string[],
  headerRowPosition: number,
  separator: string
): string[][] =>
  csvRecordsArray
    .slice(headerRowPosition + 1, headerRowPosition + 6)
    .reduce<string[][]>((acc, line) => {
      if (line) acc.push(line.split(separator));
      return acc;
    }, []);

export const createHeaderList = (headers: string[], dataCSV: string[][]): IHeaders[] =>
  headers.map((header, index) => ({
    name: header,
    data: dataCSV.map((line) => line[index]).filter((x) => x !== ""),
  }));

export const checkDataCompleteness = (headers: string[], sampleData: string[][]) => {
  const headerSize = headers.length;
  return sampleData.every((dataRow) => dataRow.length === headerSize);
};

export const generateCsvErrorMessage = (
  hasDuplicated: boolean,
  isInvalidHeaderSize: boolean,
  isInvalidDataCompleteness: boolean
) => {
  switch (true) {
    case isInvalidHeaderSize:
      return "No headers found. Please check the header row number and delimiter.";
    case isInvalidDataCompleteness:
      return "Error, corrupt document as source file contains less/more data columns than headers columns";
    case hasDuplicated:
      return "Duplicate columns in header.";
    default:
      return "An unexpected error occurred.";
  }
};

import { useState } from "react";
import { IHeaders } from "../../domain/mappingTypes";
import { AnimatePresence, motion } from "framer-motion";
import { Alert } from "@avalara/skylab-react";
import { calculateColumnChanges } from "../../utils/schema";

const ValidateHeaderChanges = ({ readHeaders }: { readHeaders: Partial<IHeaders>[] }) => {
  const { removedColumns, addedColumns } = calculateColumnChanges(readHeaders);
  const removedTextPlural = removedColumns.length > 1;
  const addedTextPlural = addedColumns.length > 1;
  const [showWarning, setShowWarning] = useState(
    addedColumns.length > 0 && removedColumns.length === 0
  );

  return (
    <>
      <AnimatePresence initial={false}>
        {showWarning && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            style={{ width: "98%" }}
          >
            <motion.div
              initial={{ marginBottom: 0 }}
              animate={{ marginBottom: 15 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
            >
              <Alert status="warning" onSDismiss={() => setShowWarning(false)}>
                <div style={{ paddingLeft: "20px" }}>
                  <div className="margin-top-sm">
                    <div className="text-sm-strong">
                      <div
                        style={{
                          fontWeight: 300,
                          fontSize: "smaller",
                          position: "absolute",
                          top: "-24px",
                        }}
                      >
                        Tip: You can ignore this warning by closing it or scrolling down
                      </div>
                      WARNING: The following column{addedTextPlural ? "s" : ""} will be added to the
                      mapping:
                    </div>
                  </div>
                  {addedColumns.length > 0 && (
                    <div className="margin-top-sm">
                      <div className="headers-preview margin-bottom-none">
                        {addedColumns.map((item, index) => (
                          <span key={item}>
                            <span style={{ color: "green" }}>{item}</span>
                            {index < addedColumns.length - 1 ? ", " : ""}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Alert>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {removedColumns.length > 0 && (
        <div style={{ width: "100%" }}>
          <Alert status="error" noDismiss>
            <div>
              The following column{removedTextPlural ? "s are" : " is"} missing in the new imported
              file:
            </div>
            <div>
              {removedColumns.map((item, index) => (
                <span key={item}>
                  <span style={{ color: "red" }}>{item}</span>
                  {index < removedColumns.length - 1 ? ", " : ""}
                </span>
              ))}
            </div>
          </Alert>
        </div>
      )}
    </>
  );
};

export default ValidateHeaderChanges;
